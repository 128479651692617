import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import jwtDecode from 'jwt-decode';

// Import any store modules
import sub1 from '@/state/modules/sub1';

Vue.use(Vuex);

// === SERVICES
const configPromises = {};
function fetchConfig(url) {
	// 1. Check if we already have the config promise
	if (configPromises[url] !== void 0) {
		return configPromises[url];
	}

	// 2. Fetch the config and extract data from response
	configPromises[url] = axios.get(url).then(response => response.data);

	// 3. Return promise
	return configPromises[url];
}

// === STATE
// Prepare initial state, store builder
const initialState = {
	// Loading state
	loading: false,

	// Session/config
	config: null,
	userInfo: null,

	// Global data
	messages: [],
	route: null,
};

// === GETTERS

function userIsAdminGetter(_state, getters) {
	const roles = getters.getUserRoles;
	return roles.indexOf('AppRole-vcmt-admin') !== -1;
}

function userRolesGetter(state) {
	const { userInfo } = state;
	if (!userInfo) return [];
	return userInfo.AppRole || [];
}

// === MUTATIONS
function setLoadingMutation(state, data) {
	state.loading = data;
}
function setConfigMutation(state, data) {
	state.config = data;
}
function clearConfigMutation(state) {
	state.config = null;
}
function setUserMutation(state, data) {
	const accessToken = jwtDecode(data.accessToken);
	const appRole = accessToken.AppRole || [];
	state.userInfo = {
		...data.user,
	};
}
function clearUserMutation(state) {
	state.userInfo = null;
}
function addMessageMutation(state, data) {
	state.messages.push(data);
}
function removeMessageMutation(state, messageId) {
	state.messages = state.messages.filter(msg => msg.id !== messageId);
}

// === ACTIONS
const getConfigAction = (context, url = '/config.json') => {
	const { state, commit } = context;

	return fetchConfig(url)
		.then((data) => {
			// Only set store if not already set
			if (!state.config) {
				commit('SET_CONFIG', data);
			}
			return data;
		});
};

let maxMessageId = 0;
const createMessageAction = ({ commit }, data) => {
	// Create unique id for the message
	let messageId = (data.type ? data.type : 'msg');
	messageId = `${messageId}_${maxMessageId}`;
	maxMessageId += 1;

	// Commit message
	commit('ADD_MESSAGE', {
		...data,
		id: messageId,
	});

	// Set timer to remove message
	const timeout = (typeof data.timeout !== 'undefined') ? data.timeout : 3000;
	if (timeout) {
		setTimeout(() => { commit('REMOVE_MESSAGE', messageId); }, timeout);
	}

	// Return resolved promise with the id of the message
	return Promise.resolve(messageId);
};
const removeMessageAction = ({ commit }, messageId) => {
	commit('REMOVE_MESSAGE', messageId);
};

// Export store instance to attach to app
export default new Vuex.Store({
	state: initialState,
	getters: {
		getUserRoles: userRolesGetter,
		getUserIsAdmin: userIsAdminGetter,
	},
	mutations: {
		SET_LOADING: setLoadingMutation,
		SET_CONFIG: setConfigMutation,
		CLEAR_CONFIG: clearConfigMutation,
		SET_USER: setUserMutation,
		CLEAR_USER: clearUserMutation,
		ADD_MESSAGE: addMessageMutation,
		REMOVE_MESSAGE: removeMessageMutation,
	},
	actions: {
		getConfig: getConfigAction,
		createMessage: createMessageAction,
		removeMessage: removeMessageAction,
	},
	modules: {
		sub1,
	},
});
