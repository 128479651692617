import Vue from 'vue';
import { OktaAuth } from '@okta/okta-auth-js'
import OktaVue from '@okta/okta-vue';
import vClickOutside from 'v-click-outside';
import '@/components/_globals';
import store from '@/state/store';
import router from '@/router';
import App from './App.vue';

// Register any global vue components
Vue.use(vClickOutside);


// Immediately set 'loading' message
store.commit('ADD_MESSAGE', {
	id: 'loading-config',
	type: 'info',
	message: 'Loading config...',
	timeout: 0,
});

// Start loading app config
const configPromise = store.dispatch('getConfig', '/config.json')
	.then((config) => {
		config.sso.redirectUri = window.location.origin + config.sso.redirectUri;
		// alert(config.sso.redirect_uri);
		const oktaAuth = new OktaAuth({
			...config.sso,
		});
		Vue.use(OktaVue, { oktaAuth });
	})
	.catch((e) => {
		// eslint-disable-next-line no-console
		console.log(e);
		console.error('Unable to auth - missing/invalid config');
		Vue.use(OktaVue, {
			issuer: ' ',
			clientId: ' ',
			redirectUri: ' ',
			scopes: [],
		});
		throw e;
	})
	.finally(() => {
		store.commit('REMOVE_MESSAGE', 'loading-config');
	});

Vue.config.productionTip = false;

// You can also pass in the default options
var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo, {
	container: "body",
	duration: 500,
	easing: "ease",
	offset: 0,
	force: true,
	cancelable: true,
	onStart: false,
	onDone: false,
	onCancel: false,
	x: false,
	y: true
})

new Vue({
	router,
	store,
	render: h => h(App, {
		props: {
			configPromise
		},
	}),
}).$mount('#wrapper');
