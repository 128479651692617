<script>
import AppHeader from '@/components/AppHeader.vue';
import PageHeader from '@/components/PageHeader.vue';
import moment from 'moment'
import SplunkLogging from '@/mixins/SplunkLogging.js'

export default {
	components: {
		AppHeader,
		PageHeader
	},
	mixins: [SplunkLogging],
	page: {
		// All subcomponent titles will be injected into this template.
		titleTemplate(title) {
			const siteName = 'Business Operations Protection';
			const titleStr = (typeof title === 'function') ? title(this.$store) : title;
			return titleStr ? `${titleStr} :: ${siteName}` : siteName;
		},
	},
	props: {
		configPromise: {
			type: Promise,
			required: true,
		},
	},
	data() {
		return {
			authenticated: false,
			showLeftSidebar: false,
			darkMode: false,
			config: {},
			pageHeader: {
				title: '',
				headerTypeSpeed: undefined,
				headerBackspaceSpeed: undefined,
				headerBackspaceDelay: undefined,
				headerNumLoops: undefined,
				headerSmartBackspace: undefined,
				headerShuffle: undefined,
				headerCursor: undefined,
				headerClass: undefined
			}
		};
	},
	computed: {
		appClasses() {
			const classes = {
				authenticated: !!this.authenticated,
				'error-page': false,
			};
			const routeName = `${this.$route.name}`;
			classes['error-page'] = ['404', 'auth-error'].indexOf(routeName) > -1;
			return classes;
		},
		messages() {
			return this.$store.state.messages;
		},
		// This is used to return the value of the title for the PageHeader component's key
		// so that when the title changes, the component is rerendered.
		pageTitle() {
            if(typeof this.pageHeader.title === 'string') {
                return this.pageHeader.title
            }else{
                return this.pageHeader.title.join('|')
            }
        }
	},
	watch: {
		// Every time the route changes, check the auth status
		$route(to) {
			const authPromise = this.configPromise
				.finally(() => this.checkAuthentication(to));

			// When created, delay some actions until after authenticated
			authPromise.then(() => {
				this.showLeftSidebar = true;
				this.logPageView();
			});
		},
		darkMode: function(val) {
			if(val === true) {
				// Enable dark mode
				$('body').addClass('dark-mode')
				$('#header').addClass('bg-gradient-dark');
				$('.dropdown-menu').addClass('dropdown-menu-dark')
				$('#dropdownProfileMenu, #btnGlobalSearch').removeClass('btn-primary');
				$('#dropdownProfileMenu, #btnGlobalSearch').addClass('btn-dark');
			}else{
				// Disable dark mode
				$('body').removeClass('dark-mode')
				$('#header').removeClass('bg-gradient-dark');
				$('.dropdown-menu').removeClass('dropdown-menu-dark')
				$('#dropdownProfileMenu, #btnGlobalSearch').removeClass('btn-dark');
				$('#dropdownProfileMenu, #btnGlobalSearch').addClass('btn-primary');
			}
			localStorage.darkMode = val;
		},
		authenticated: function(newValue, oldValue) {
			if(newValue && !oldValue) {
				this.loadDocumentTypes();
				this.getCurrentEmployee();
				this.loadFAQCategories();
			}
		},
	},
	methods: {
		async checkAuthentication(to) {
			try {
				const previouslyLoggedIn = this.authenticated;
				this.authenticated = await this.$auth.isAuthenticated();
				const justLoggedIn = !previouslyLoggedIn && this.authenticated;
				if (justLoggedIn) {
					try {
						const oktaUserInfo = await this.$auth.getUser();
						const oktaAccessToken = this.$auth.getAccessToken();
						this.$store.commit('SET_USER', {
							user: oktaUserInfo,
							accessToken: oktaAccessToken,
						});
					} catch (e) {
						console.error(e); // eslint-disable-line no-console
						this.$store.commit('CLEAR_USER');
						this.authenticated = false;
						this.$store.commit('REMOVE_MESSAGE', 'authenticating');
						// this.$auth.signInWithRedirect();
					}
				}
				// If just logged in, or if new route doesn't require auth, go ahead and clear auth message
				const skipAuth = (to && !to.meta.requiresAuth);
				if (justLoggedIn || skipAuth) this.$store.commit('REMOVE_MESSAGE', 'authenticating');
				const justLoggedOut = previouslyLoggedIn && !this.authenticated;
				if (justLoggedOut) {
					this.$store.commit('CLEAR_USER');
					this.authenticated = false;
				}
				return this.$store.state.userInfo;
			} catch (e) {
				this.$store.commit('CLEAR_USER');
				this.authenticated = false;
				return this.$store.state.userInfo;
			}
		},
		async getCurrentEmployee() {
			await this.checkAuthentication();
			if(this.authenticated) {
				if(localStorage.getItem("employee") === null) {
					const { data } = await this.$http.post(
						'/api/employee/v1/Employees/current',
						JSON.stringify({})
					);
					if(data) {
						localStorage.setItem('employee', JSON.stringify(data));
					}
				}
			}
		},
		async loadDocumentTypes() {
            const { data } = await this.$http.get(
              '/api/content/v1/DocumentTypes'
			);
			localStorage.setItem("documentTypes", JSON.stringify(data));
		},
		async loadFAQCategories() {
            const { data } = await this.$http.get(
              '/api/faq/v1/FAQ/categories'
			);
			localStorage.setItem("faqCategories", JSON.stringify(data));
		},
		logout() {
			// Show message, wait 1 sec for UX
			const msg = {
				id: 'logout',
				type: 'info',
				message: 'Logging out...',
				timeout: 0,
			};
			this.$store.commit('ADD_MESSAGE', msg);
			setTimeout(
				async () => {
					try {
						await this.$auth.signOut();
						await this.checkAuthentication();
					} catch (e) {
						// It's possible that the error just means user is already logged out.
						// Do nothing except log error
						console.error(e); // eslint-disable-line no-console
					}
					this.$store.dispatch('REMOVE_MESSAGE', 'logout');
					// Redirect internal
					// this.$router.push({ name: 'home' });
					// Redirect to login page
					this.$auth.signInWithRedirect();
				},
				1000,
			);
		},
		clearMessage(messageId) {
			this.$store.dispatch('REMOVE_MESSAGE', messageId);
		},
		getCurrentYear() {
			return moment(new Date()).year();
		},
		async updatePageHeader(headerData) {
			Object.assign(this.pageHeader, headerData);
		}
	},
	mounted() {
		if(localStorage.getItem("darkMode") !== null) {
			this.darkMode = JSON.parse(localStorage.darkMode);
		}
		
		// Rebind internal links to the Vue router:
		// https://dennisreimann.de/articles/delegating-html-links-to-vue-router.html
		window.addEventListener('click', event => {
			// ensure we use the link, in case the click has been received by a subelement
			let { target } = event
			while (target && target.tagName !== 'A') target = target.parentNode
			// handle only links that do not reference external resources
			if (target && target.matches("a:not([href*='://'])") && target.href) {
				// some sanity checks taken from vue-router:
				// https://github.com/vuejs/vue-router/blob/dev/src/components/link.js#L106
				const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } = event
				// don't handle with control keys
				if (metaKey || altKey || ctrlKey || shiftKey) return
				// don't handle when preventDefault called
				if (defaultPrevented) return
				// don't handle right clicks
				if (button !== undefined && button !== 0) return
				// don't handle if `target="_blank"`
				if (target && target.getAttribute) {
					const linkTarget = target.getAttribute('target')
					if (/\b_blank\b/i.test(linkTarget)) return
				}
				// don't handle same page links/anchors
				const url = new URL(target.href)
				const to = url.pathname
				if (window.location.pathname !== to && event.preventDefault) {
					event.preventDefault()
					this.$router.push(to)
				}
			}else{
				$(target).attr('rel', 'noreferrer noopener');
				$(target).attr('target', '_blank');
			}
		});
	}
};
</script>
<template>
	<div
		id="wrapper"
		:class="appClasses"
	>
		<AppHeader
			id="header"
			:authenticated="authenticated"
			:user="$store.state.userInfo"
			:route="$route"
			@logout="logout"
		/>
		<div id="app-body">
			<!--
			Even when routes use the same component, treat them
			as distinct and create the component again.
			-->
			<PageHeader
				:title="pageHeader.title"
				:headerTypeSpeed="pageHeader.headerTypeSpeed"
				:headerBackspaceSpeed="pageHeader.headerBackspaceSpeed"
				:headerBackspaceDelay="pageHeader.headerBackspaceDelay"
				:headerNumLoops="pageHeader.headerNumLoops"
				:headerSmartBackspace="pageHeader.headerSmartBackspace"
				:headerShuffle="pageHeader.headerShuffle"
				:headerCursor="pageHeader.headerCursor"
				:headerClass="pageHeader.headerClass"
				:user="$store.state.userInfo"
				:route="$route"
				:key="pageTitle"
				ref="pageHeader"
			/>
			<RouterView
				:key="$route.fullPath"
				class="app-view"
				:authenticated="authenticated"
				v-on:updatePageHeader="updatePageHeader"
				@logout="logout"
			/>
		</div>
        <!-- FOOTER -->
        <footer id="footer" class="footer-dark footer-sticky">
			<div class="bg-distinct py-3 clearfix">
				<div class="container clearfix text-center-xs">
					<div class="fs--13 py-2 float-start float-none-xs m-0-xs">
						&copy; {{ getCurrentYear() }} Vivint. Information contained on this site is considered proprietary and confidential.
					</div>
				</div>
			</div>
        </footer>
        <!-- /FOOTER -->
	</div>
</template>
<style lang="scss">
	@import "assets/global.scss";
</style>
