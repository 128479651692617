// Helpers for creating specially cased versions of string
const cleanup = str => str.trim().replace(
	/^[-_]+(.*?)[-_]+$/g,
	'$1',
);

export const camelCase = str => cleanup(str).toLowerCase().replace(
	/\W+(.)/g,
	(_match, chr) => chr.toUpperCase(),
);

export const kebabCase = str => camelCase(str).replace(
	/([a-z])([A-Z])/g,
	'$1-$2',
).replace(
	/\s+/g,
	'-',
).toLowerCase();

export const pascalCase = (str) => {
	const camelized = camelCase(str);
	return camelized.charAt(0).toUpperCase() + camelized.slice(1);
};
