// Globally register all base components for convenience, because they
// will be used very frequently. Components are registered using the
// PascalCased version of their filename.

import Vue from 'vue';
import { pascalCase } from '@/modules/string-casing';

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
	// Look for files in the current directory
	'.',
	// Do not look in subdirectories
	false,
	// Only include "_base-" prefixed .vue files
	/_base-[\w-]+\.vue$/,
);

// For each matching filename...
requireComponent.keys().forEach((fileName) => {
	// Get the component config
	const componentConfig = requireComponent(fileName);
	// Get the PascalCase version of the component name
	const componentName = pascalCase(fileName
	// Remove the "./_" from the beginning
		.replace(/^\.\/_/, '')
	// Remove the file extension from the end
		.replace(/\.\w+$/, ''));
	// Globally register the component
	Vue.component(componentName, componentConfig.default || componentConfig);
});
