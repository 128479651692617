<script>
export default {
	name: 'ProfileDropdown',
	props: {
		user: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			showProfileMenu: false,
		};
	},
	methods: {
		outsideClicked() {
			this.showProfileMenu = false;
		},
		logout() {
			this.showProfileMenu = false;
			this.$emit('logout');
		}
	},
	mounted() {
		
	}
};
</script>
<template>
	<div class="profile">
		<span class="user-name">{{ user ? user.name : '' }}</span>
		<span
			class="avatar"
			@click="showProfileMenu = !showProfileMenu"
		>
		</span>
		<nav
			v-if="showProfileMenu"
			v-click-outside="outsideClicked"
			class="profile-nav"
		>
			<ul>
				<!--<li><a href="#">Option 1</a></li>
				<li><a href="#">Option 2</a></li>
				<li><a href="#">Option 3</a></li>-->
				<li class="logout"><button @click="logout">Log out</button></li>
			</ul>
		</nav>
	</div>
</template>
