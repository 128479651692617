<script>
export default {
	name: 'QuickSearch',
	props: {
		user: {
			type: Object,
			default: null,
        },
	},
	data() {
		return {
            showProfileMenu: false,
            searchOpen: false,
            searchQuery: '',
            documentSearchResults: [],
            faqContentSearchResults: [],
            lastSearch: '',
            autoSuggestTimer: null,
            minimumSearchThreshold: 2,
            inputDelay: 250,
            maximumResultsToDisplay: 10,
            documentTypes: [],
            faqCategories: {}
		};
	},
	methods: {
		outsideClicked() {
			this.showProfileMenu = false;
        },
        processDocumentKeyUpEvent(evt) {
            if(evt.keyCode === 27) { // 27 = ESC key
                this.toggleSearch();
            }
        },
        processInputKeyUpEvent(evt) {
            this.searchQuery = $(evt.target).val();
            if(this.searchQuery.length >= Number(this.minimumSearchThreshold) && this.lastSearch != this.searchQuery) {
                $('.sow-search-loader').removeClass('hide');
                if(this.autoSuggestTimer) {
                    clearTimeout(this.autoSuggestTimer);
                }
                this.autoSuggestTimer = setTimeout(this.performSearch, this.inputDelay);
            }
            
            evt.stopPropagation();
            evt.preventDefault();
        },
        async performDocumentSearch() {
            var component = this;
            component.documentSearchResults = [];
            this.$http.post(
              '/api/content/v1/Documents/search',
              JSON.stringify({ "terms": this.searchQuery.split(' '), "searchType": "All"}),
				{
				  headers: {'Content-Type': 'application/json'}
				}
            ).then(function(response) {
                var documentSearchResults = [];
                response.data.forEach((searchResult, idx) => {
                    if(component.documentTypes[searchResult.documentTypeId].slug == searchResult.slug) {
                        // This is an index document, show the library name and link directly to it
                        searchResult.name = component.documentTypes[searchResult.documentTypeId].name;
                        searchResult.url = '/' + component.documentTypes[searchResult.documentTypeId].slug;
                    }else{
                        searchResult.url = '/' + component.documentTypes[searchResult.documentTypeId].slug + '/' + searchResult.slug;
                    }
                    if(documentSearchResults[searchResult.documentTypeId] === undefined) {
                        documentSearchResults[searchResult.documentTypeId] = [];
                    }
                    documentSearchResults[searchResult.documentTypeId].push(searchResult);
                });
                component.documentSearchResults = documentSearchResults;
                $('.sow-search-loader').addClass('hide');
            });
        },
        async performFAQContentSearch() {
            var component = this;
            component.faqContentSearchResults = [];
            this.$http.post(
              '/api/faq/v1/FAQ/search',
              JSON.stringify({ "terms": this.searchQuery.split(' '), "searchType": "All"}),
				{
				  headers: {'Content-Type': 'application/json'}
				}
            ).then(function(response) {
                var faqContentSearchResults = [];
                response.data.forEach((searchResult, idx) => {
                    if(component.faqCategories[searchResult.faqCategoryId].slug == searchResult.slug) {
                        // This is an index document, show the library name and link directly to it
                        searchResult.name = component.faqCategories[searchResult.faqCategoryId].name;
                        searchResult.url = '/faq/' + component.faqCategories[searchResult.faqCategoryId].slug;
                    }else{
                        searchResult.url = '/faq/' + component.faqCategories[searchResult.faqCategoryId].slug + '/' + searchResult.slug;
                    }
                    faqContentSearchResults.push(searchResult);
                });
                component.faqContentSearchResults = faqContentSearchResults;
                $('.sow-search-loader').addClass('hide');
            });
        },
        async performSearch() {
            this.lastSearch = this.searchQuery;
            this.performDocumentSearch();
            this.performFAQContentSearch();
        },
        toggleSearch() {
            if(this.searchOpen) {
                // Hide search
                $.SOW.globals.elBody.removeClass('overflow-hidden');
                $('.sow-search-backdrop').addClass('hide');
                $('.sow-search-container').addClass('hide');
                $('.sow-search-over').addClass('hide');
                $('input.form-control-sow-search').blur();
                $('input.form-control-sow-search').removeClass('sow-search-mobile');
                $('.sow-search-over').addClass('hide'); // all of them, if many available
                this.searchOpen = false;
            }else{
                // Show search bar
                $('.sow-search').addClass('sow-search-mobile');
                $('.sow-search'+'.sow-search-over').removeClass('hide');
                $('.sow-search input.form-control-sow-search').focus();
                $.SOW.globals.elBody.addClass('overflow-hidden');
                
                // Show results container
                $('.form-control-sow-search').removeClass('hide');
                $('.sow-search-backdrop').removeClass('hide');
                $('#sow-search-container').removeClass('hide');
                this.searchOpen = true;
            }
        },
        searchResultClicked(event) {
            this.toggleSearch();
        }
    },
    mounted() {
		var documentTypes = localStorage.getItem("documentTypes");
		if(documentTypes !== null) {
			documentTypes = JSON.parse(documentTypes);
            if(documentTypes.length > 0) {
                documentTypes.forEach((documentType, idx) => {
                    this.documentTypes[documentType.id] = documentType;
                });
            }
        }
		var faqCategories = localStorage.getItem("faqCategories");
		if(faqCategories !== null) {
			faqCategories = JSON.parse(faqCategories);
            if(faqCategories.length > 0) {
                faqCategories.forEach((faqCategory, idx) => {
                    this.faqCategories[faqCategory.id] = faqCategory;
                });
            }
        }
        $.SOW.core.search_suggest.config.enable = false;

        $(document).on('keyup', this.processDocumentKeyUpEvent);
        $('.form-control-sow-search').on('keyup', this.processInputKeyUpEvent);

        $('.sow-search-backdrop').on('click', this.toggleSearch);
        $('form.sow-search').on('submit', function() {
            return false;
        });
    }
};
</script>
<template>
    <!-- 
        Based on the UI for the SOW: Search Suggest plugin, but converted to a Vue component and with custom search logic.
    -->
    <form 	id="quicksearch"
            action="/search" 
            method="GET" 
            data-autosuggest="off" 

            data-mode="json" 
            data-json-max-results='10'
            data-json-related-title='Search Results'
            data-json-related-item-icon='fi fi-star-empty'
            data-json-suggest-title='Suggestions for you'
            data-json-suggest-noresult='No results for'
            data-json-suggest-item-icon='fi fi-search'
            data-json-suggest-min-score='5'
            data-json-highlight-term='true'
            data-contentType='application/json; charset=utf-8'
            data-dataType='json'

            data-container="#sow-search-container"
            data-related-keywords="" 
            data-related-url="_ajax/search_suggest_related.json" 
            data-suggest-url="/api/content/api/v1/Documents/search" 
            data-related-action="related_get" 
            data-suggest-action="suggest_get"
            class="sow-search sow-search-over hide d-inline-flex">
        <div class="sow-search-input w-100">
            <div class="input-group-over d-flex align-items-center w-100 h-100 rounded shadow-md">
                <input placeholder="what are you looking for?" aria-label="what are you looking for?" name="s" type="text" class="form-control-sow-search form-control form-control-lg shadow-xs" value="" autocomplete="off">
                <span class="sow-search-buttons">
                    <!-- search button -->
                    <button aria-label="Global Search" type="submit" class="btn bg-transparent shadow-none m-0 px-2 py-1 text-muted">
                        <i class="fi fi-search fs--20"></i>
                    </button>
                    <!-- close : mobile only (d-inline-block d-lg-none) -->
                    <a href="#" class="btn-sow-search-toggler btn btn-light shadow-none m-0 px-2 py-1 d-inline-block d-lg-none" onclick="return(false);">
                        <i class="fi fi-close fs--20"></i>
                    </a>
                </span>
            </div>
        </div>

        <!-- search suggestion container -->
        <div class="sow-search-container w-100 p-0 hide shadow-md" id="sow-search-container">
            <div class="sow-search-container-wrapper">
                <!-- main search container -->
                <div class="sow-search-loader p-3 text-center hide">
                    <i class="fi fi-circle-spin fi-spin text-muted fs--30"></i>
                </div>
                <!-- 
                    AJAX CONTENT CONTAINER 
                    SHOULD ALWAYS BE AS IT IS : NO COMMENTS OR EVEN SPACES!
                --><div class="sow-search-content rounded w-100 scrollable-vertical">
                    <h3 class="fs--18 p-4 m-0 text-secondary">Search Results</h3>
                    <ul class="list-unstyled list-suggestion">
                        <li v-if="this.documentSearchResults.length == 0 && this.faqContentSearchResults.length == 0" class="list-item text-muted px-4 pb-4">
							No results for <i>"{{ this.searchQuery }}"</i>
						</li>
                        <template v-if="faqContentSearchResults.length > 0">
                            <li v-bind:key="'faqSearchResultHeader'" class="list-item pl-4 pt-2 pb-2" data-score="[SCORE]">
                                <i class="fad fa-question-circle"></i> <b>Frequently Asked Questions</b>
                            </li>
                            <template v-for="(result, resultIdx) in faqContentSearchResults">
                                <li v-bind:key="'searchResult-' + resultIdx + '-' + resultIdx" class="list-item" data-score="[SCORE]">
                                    <router-link :to="result.url"  v-on:click.native="searchResultClicked">
                                        <div class="pl--25">{{ result.question }}</div>
                                    </router-link>
                                </li>
                            </template>
                        </template>
                        <template v-for="(documentType, documentTypeIdx) in documentTypes">
                            <li v-show="documentSearchResults[documentType.id] !== undefined && documentSearchResults[documentType.id].length > 0" v-bind:key="'documentType-' + documentTypeIdx" class="list-item pl-4 pt-2 pb-2" data-score="[SCORE]">
                                <i :class="documentTypes[documentTypeIdx].iconClass"></i> <b>{{ documentTypes[documentTypeIdx].name.replace(/^Security\s/, '') }}</b>
                            </li>
                            <template v-for="(result, resultIdx) in documentSearchResults[documentType.id]">
                                <li v-if="result.documentTypeId == documentType.id" v-bind:key="'searchResult-' + documentTypeIdx + '-' + resultIdx" class="list-item" data-score="[SCORE]">
                                    <router-link :to="result.url"  v-on:click.native="searchResultClicked">
                                        <div class="pl--25">{{ result.name }}</div>
                                    </router-link>
                                </li>
                            </template>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
        <!-- /search suggestion container -->
        <!-- 

            overlay combinations:
                overlay-dark opacity-* [1-9]
                overlay-light opacity-* [1-9]

        -->
        <div class="sow-search-backdrop overlay-dark opacity-3 hide"></div>
    </form>
</template>
