import Vue from 'vue';
import { LoginCallback } from '@okta/okta-vue'

// Route components imported this way are lazy-loaded, for performance
const Home = () => import(/* webpackChunkName: "Home" */ '@/router/views/Home.vue');
const Engage = () => import(/* webpackChunkName: "Engage" */ '@/router/views/Engage.vue');
const Requirements = () => import(/* webpackChunkName: "Requirements" */ '@/router/views/Requirements/RequirementsWizard.vue');
const RequirementsProject = () => import(/* webpackChunkName: "RequirementsProject" */ '@/router/views/Requirements/RequirementsProject.vue');
const Policy = () => import(/* webpackChunkName: "Policy" */ '@/router/views/Policy.vue');
const Policies = () => import(/* webpackChunkName: "Policies" */ '@/router/views/Policies.vue');
const Document = () => import(/* webpackChunkName: "Document" */ '@/router/views/Document.vue');
const FAQ = () => import(/* webpackChunkName: "FAQ" */ '@/router/views/FAQ.vue');
const Advisories = () => import(/* webpackChunkName: "Alerts" */ '@/router/views/Advisories.vue');
const Phishing = () => import(/* webpackChunkName: "Alerts" */ '@/router/views/Phishing.vue');
const News = () => import(/* webpackChunkName: "Alerts" */ '@/router/views/News.vue');
const DataClassifications = () => import(/* webpackChunkName: "DataClassifications" */ '@/router/views/DataClassifications.vue');
const ReportSecurityConcern = () => import(/* webpackChunkName: "ReportSecurityConcern" */ '@/router/views/ReportSecurityConcern.vue');
const Search = () => import(/* webpackChunkName: "Search" */ '@/router/views/Search.vue');
const Team = () => import(/* webpackChunkName: "Team" */ '@/router/views/Team.vue');

const route401 = () => import(/* webpackChunkName: "Error401" */ '@/router/views/401.vue');
const route404 = () => import(/* webpackChunkName: "Error404" */ '@/router/views/404.vue');

const Maintenance = () => import(/* webpackChunkName: "Maintenance" */ '@/router/views/Maintenance.vue');
const Reset = () => import(/* webpackChunkName: "Reset" */ '@/router/views/Reset.vue');

export default [
    {
        name: 'home',
        path: '/',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    // Handle the redirect from Okta using the Okta Vue SDK
    {
        name: 'auth-callback',
        path: '/implicit/callback',
        // To use built-in callback, which doesn't handle errors very well
        component: LoginCallback,
    },
    // Handle error redirects from Okta login errors
    {
        name: 'auth-error',
        path: '/auth-error',
        component: route401,
        hidden: true,
        props: (route) => {
            const props = {
                code: '',
                message: '',
            };
            if (!route.params) return props;
            const { params } = route;
            if (params.errorCode) props.code = params.errorCode;
            if (params.code) props.code = params.code;
            if (params.message) props.message = params.message;
            return props;
        },
        meta: {
            requiresAuth: false,
        },
    },
    {
        name: 'maintenance',
        path: '/maintenance',
        component: Maintenance,
        meta: {
            requiresAuth: false,
        },
    },
    {
        name: 'engage',
        path: '/engage',
        component: Engage,
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: 'requirements',
        path: '/requirements',
        component: Requirements,
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: 'requirements_project',
        path: '/requirements/project/:projectId',
        component: RequirementsProject,
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: 'classifications',
        path: '/classifications',
        component: DataClassifications,
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: 'team',
        path: '/team',
        component: Team,
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: 'search',
        path: '/search',
        component: Search,
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: 'report',
        path: '/report',
        component: ReportSecurityConcern,
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: 'faq',
        path: '/faq',
        component: FAQ,
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: 'faq_category',
        path: '/faq/:faqCategoryId',
        component: FAQ,
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: 'faq_question',
        path: '/faq/:faqCategorySlug/:faqContentSlug?',
        component: FAQ,
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: 'advisories',
        path: '/alerts/advisories',
        component: Advisories,
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: 'phishing',
        path: '/alerts/phishing',
        component: Phishing,
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: 'news',
        path: '/news',
        component: News,
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: 'reset',
        path: '/reset',
        component: Reset,
        meta: {
            requiresAuth: false,
        },
    },
    {
        name: 'document_path_simple',
        path: '/:name/:slug',
        component: Document,
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: 'document_path_without_folder',
        path: '/:name/:slug/:section?/:page(.*?.*)?',
        component: Document,
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: 'document_path_with_folder',
        path: '/:name/:folder/:slug/:section?/:page(.*?.*)?',
        component: Document,
        meta: {
            requiresAuth: true,
        },
    },
    {
        name: 'library_index',
        path: '/:name',
        component: Document,
        meta: {
            requiresAuth: true,
        },
    },
    // Handle any unmatched routes with the 404 page.
    // This has been changed from a redirect to a direct component
    // to avoid changing URL to /404 until something like
    // https://github.com/vuejs/vue-router/issues/977 is available.
    {
        path: '*',
        name: '404',
        component: route404,
        hidden: true,
        // Allows props to be passed to the 404 page through route
        // params, such as `resource` to define what wasn't found.
        props: true,
    },
];
