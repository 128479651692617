<script>
import ProfileDropdown from '@/components/ProfileDropdown.vue';
import QuickSearch from '@/components/QuickSearch.vue';
export default {
	name: 'AppHeader',
	components: {
		ProfileDropdown,
		QuickSearch,
	},
	props: {
		authenticated: Boolean,
		user: {
			type: Object,
			default: null,
		},
		route: {
			type: Object,
			default: null,
		},
	},
	methods: {
		toggleSearch: function() {
			this.$refs.quickSearch.toggleSearch();
		}
	},
	data() {
		return {};
	},
	mounted() {
		$.SOW.globals.elHeader = $('#header');
		$.SOW.core.header.init()

		// Ensure that menu closes automatically on mobile when tapping links
		$('.dropdown-link', $('.dropdown-menu').not('.dropdown-click-ignore')).on('click', function(evt) { $(evt.target).parents('.navbar-collapse.show').collapse('toggle'); });
	}
};
</script>
<template>
	<!-- AppHeader -->
	<header id="header" class="shadow-xs">
		<div class="container position-relative">
					<QuickSearch
						id="search"
						ref="quickSearch"
						:authenticated="authenticated"
						:user="$store.state.userInfo"
						:route="$route"
					/>
					<nav class="navbar navbar-expand-lg navbar-dark justify-content-lg-between justify-content-md-inherit">

						<div class="align-items-start">

							<!-- mobile menu button : show -->
							<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMainNav" aria-controls="navbarMainNav" aria-expanded="false" aria-label="Toggle navigation">
								<svg width="25" viewBox="0 0 20 20">
									<path d="M 19.9876 1.998 L -0.0108 1.998 L -0.0108 -0.0019 L 19.9876 -0.0019 L 19.9876 1.998 Z"></path>
									<path d="M 19.9876 7.9979 L -0.0108 7.9979 L -0.0108 5.9979 L 19.9876 5.9979 L 19.9876 7.9979 Z"></path>
									<path d="M 19.9876 13.9977 L -0.0108 13.9977 L -0.0108 11.9978 L 19.9876 11.9978 L 19.9876 13.9977 Z"></path>
									<path d="M 19.9876 19.9976 L -0.0108 19.9976 L -0.0108 17.9976 L 19.9876 17.9976 L 19.9876 19.9976 Z"></path>
								</svg>
							</button>

							<!--
								Logo : height: 70px max
							-->
							<router-link :to="{path:'/'}" class="logo inverse" replace>
								<img src="/assets/img/vivint.svg" height="34" alt="" /> <span>Business Operations Protection</span>
							</router-link>

						</div>




						<!-- Menu -->
						<!--

							Dropdown Classes (should be added to primary .dropdown-menu only, dropdown childs are also affected)
								.dropdown-menu-dark 		- dark dropdown (desktop only, will be white on mobile)
								.dropdown-menu-hover 		- open on hover
								.dropdown-menu-clean 		- no background color on hover
								.dropdown-menu-invert 		- open dropdown in oposite direction (left|right, according to RTL|LTR)
								.dropdown-menu-uppercase 	- uppercase text (font-size is scalled down to 13px)
								.dropdown-click-ignore 		- keep dropdown open on inside click (useful on forms inside dropdown)

								Repositioning long dropdown childs (Example: Pages->Account)
									.dropdown-menu-up-n100 		- open up with top: -100px
									.dropdown-menu-up-n100 		- open up with top: -150px
									.dropdown-menu-up-n180 		- open up with top: -180px
									.dropdown-menu-up-n220 		- open up with top: -220px
									.dropdown-menu-up-n250 		- open up with top: -250px
									.dropdown-menu-up 			- open up without negative class


								Dropdown prefix icon (optional, if enabled in variables.scss)
									.prefix-link-icon .prefix-icon-dot 		- link prefix
									.prefix-link-icon .prefix-icon-line 	- link prefix
									.prefix-link-icon .prefix-icon-ico 		- link prefix
									.prefix-link-icon .prefix-icon-arrow 	- link prefix

								.nav-link.nav-link-caret-hide 	- no dropdown icon indicator on main link
								.nav-item.dropdown-mega 		- required ONLY on fullwidth mega menu

								Mobile animation - add to .navbar-collapse:
								.navbar-animate-fadein
								.navbar-animate-bouncein

						-->
						<div class="collapse navbar-collapse navbar-animate-fadein justify-content-end" id="navbarMainNav" v-if="authenticated">


							<!-- MOBILE MENU NAVBAR -->
							<div class="navbar-xs d-none"><!-- .sticky-top -->

								<!-- mobile menu button : close -->
								<button class="navbar-toggler pt-0" type="button" data-toggle="collapse" data-target="#navbarMainNav" aria-controls="navbarMainNav" aria-expanded="false" aria-label="Toggle navigation">
									<svg width="20" viewBox="0 0 20 20">
										<path d="M 20.7895 0.977 L 19.3752 -0.4364 L 10.081 8.8522 L 0.7869 -0.4364 L -0.6274 0.977 L 8.6668 10.2656 L -0.6274 19.5542 L 0.7869 20.9676 L 10.081 11.679 L 19.3752 20.9676 L 20.7895 19.5542 L 11.4953 10.2656 L 20.7895 0.977 Z"></path>
									</svg>
								</button>

								<!--
									Mobile Menu Logo
									Logo : height: 70px max
								-->
							<div class="logo">
								<img src="/assets/img/vivint.svg" height="34" alt="" /> <span>Enterprise Security Portal</span>
							</div>

							</div>
							<!-- /MOBILE MENU NAVBAR -->



							<!-- NAVIGATION -->
							<ul class="navbar-nav">
															<!-- Menu -->
								<!--

									Dropdown Classes (should be added to primary .dropdown-menu only, dropdown childs are also affected)
										.dropdown-menu-dark 		- dark dropdown (desktop only, will be white on mobile)
										.dropdown-menu-hover 		- open on hover
										.dropdown-menu-clean 		- no background color on hover
										.dropdown-menu-invert 		- open dropdown in oposite direction (left|right, according to RTL|LTR)
										.dropdown-menu-uppercase 	- uppercase text (font-size is scalled down to 13px)
										.dropdown-click-ignore 		- keep dropdown open on inside click (useful on forms inside dropdown)

										Repositioning long dropdown childs (Example: Pages->Account)
											.dropdown-menu-up-n100 		- open up with top: -100px
											.dropdown-menu-up-n100 		- open up with top: -150px
											.dropdown-menu-up-n180 		- open up with top: -180px
											.dropdown-menu-up-n220 		- open up with top: -220px
											.dropdown-menu-up-n250 		- open up with top: -250px
											.dropdown-menu-up 			- open up without negative class


										Dropdown prefix icon (optional, if enabled in variables.scss)
											.prefix-link-icon .prefix-icon-dot 		- link prefix
											.prefix-link-icon .prefix-icon-line 	- link prefix
											.prefix-link-icon .prefix-icon-ico 		- link prefix
											.prefix-link-icon .prefix-icon-arrow 	- link prefix

										.nav-link.nav-link-caret-hide 	- no dropdown icon indicator on main link
										.nav-item.dropdown-mega 		- required ONLY on fullwidth mega menu

								-->
								<!-- mobile only image + simple search (d-block d-sm-none) -->
								<li class="nav-item d-block d-sm-none">

									<!-- image -->
									<div class="mb-4">
										<!-- <img width="600" height="600" class="img-fluid" src="demo.files/svg/artworks/people_crossbrowser.svg" alt="..."> -->
									</div>

									<!-- search -->
									<form method="get" action="#!search" class="input-group-over mb-5 bg-light p-2 form-control-pill">
										<input type="text" name="keyword" value="" placeholder="Quick search..." class="form-control border-dashed">
										<button class="btn btn-primary btn-ghost fi fi-search p-0 ml-2 mr-2 w--50 h--50"></button>
									</form>

								</li>


								<!-- home -->
								<li class="nav-item dropdown active">

									<a href="#" id="mainNavHome" class="nav-link dropdown-toggle"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false">
										Services
									</a>

									<div aria-labelledby="mainNavHome" class="dropdown-menu dropdown-menu-clean dropdown-menu-hover">
									    <ul class="list-unstyled m-0 p-0">
											<li class="dropdown-item"><router-link to="/slm" class="dropdown-link">Architecture & Development Lifecycle</router-link></li>
											<li class="dropdown-item"><router-link to="/corporate" class="dropdown-link">Corporate Security</router-link></li>
											<li class="dropdown-item"><router-link to="/soc" class="dropdown-link">Security Operations Center (SOC)</router-link></li>
											<li class="dropdown-item"><router-link to="/risk-compliance" class="dropdown-link">Risk & Compliance</router-link></li>
											<li class="dropdown-item"><router-link to="/testing" class="dropdown-link">Security Testing & Evaluation</router-link></li>
									    </ul>
									</div>

								</li>


								<!-- pages -->
								<li class="nav-item dropdown active">

									<a href="#" id="mainNavPages" class="nav-link dropdown-toggle"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false">
										Resources
									</a>

									<div aria-labelledby="mainNavPages" class="dropdown-menu dropdown-menu-clean dropdown-menu-hover">
										<li class="dropdown-item"><router-link to="/engage" class="dropdown-link">Engage the BOP Team</router-link></li>
										<div class="dropdown-divider"></div>
										<li class="dropdown-item dropdown">
											<a href="#" class="dropdown-link" data-toggle="dropdown" onclick="return(false)">Best Practices</a>
											<ul class="dropdown-menu dropdown-menu-hover dropdown-menu-block-md min-w-200">
												<li class="dropdown-item"><router-link to="/best-practices/product-security" class="dropdown-link">Product Security</router-link></li>
												<li class="dropdown-item"><router-link to="/hardening" class="dropdown-link">System Hardening Guidelines</router-link></li>
											</ul>
										</li>
										<li class="dropdown-item"><router-link to="/guides" class="dropdown-link">How-To Guides</router-link></li>
										<li class="dropdown-item"><router-link to="/awareness" class="dropdown-link">Security Awareness</router-link></li>
										<li class="dropdown-item"><router-link to="/champions" class="dropdown-link">Security Champions Program</router-link></li>
										<li class="dropdown-item"><router-link to="/tips" class="dropdown-link">Tips & Tricks</router-link></li>
										<li class="dropdown-item"><router-link to="/training" class="dropdown-link">Training Courses</router-link></li>
									</div>

								</li>


								<!-- features -->
								<!-- <li class="nav-item dropdown active">

									<a href="#" id="mainNavFeatures" class="nav-link dropdown-toggle"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false">
										Data Protection
									</a>

									<div aria-labelledby="mainNavFeatures" class="dropdown-menu dropdown-menu-clean dropdown-menu-hover">

									</div>

								</li> -->


								<!-- shop + blog -->
								<!-- <li class="nav-item dropdown active">

									<a href="#" id="mainNavShop" class="nav-link dropdown-toggle"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false">
										Policies & Procedures
									</a>

									<div aria-labelledby="mainNavShop" class="dropdown-menu dropdown-menu-hover dropdown-menu-clean">

									</div>

								</li> -->


								<!-- documentation -->
								<li class="nav-item dropdown active">

									<a href="#" id="mainNavDocumentation" class="nav-link dropdown-toggle nav-link-caret-hide"
										data-toggle="dropdown"
										aria-haspopup="true"
										aria-expanded="false">
										<span>Contact</span>
									</a>

									<div aria-labelledby="mainNavFeatures" class="dropdown-menu dropdown-menu-clean dropdown-menu-hover">
									    <ul class="list-unstyled m-0 p-0">
											<li class="dropdown-item"><router-link to="/contact" class="dropdown-link">Contact the Team</router-link></li>
									        <li class="dropdown-item"><router-link to="/report" class="dropdown-link">Report a Security Concern</router-link></li>
									        <!-- <li class="dropdown-item"><router-link to="/team" class="dropdown-link">Team Directory</router-link></li> -->
									    </ul>
									</div>
								</li>




							</ul>
							<!-- /NAVIGATION -->


						</div>





						<!-- OPTIONS -->
						<ul class="list-inline list-unstyled mb-0 d-flex align-items-end" v-if="authenticated">

							<li class="list-inline-item mx-1 dropdown">

								<a href="#" aria-label="Account Options" id="dropdownProfileMenu" class="btn btn-sm rounded-circle btn-primary btn-soft-static" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
									<span class="group-icon">
										<i class="fi fi-user-male"></i>
										<i class="fi fi-close"></i>
									</span>
								</a>


								<!--

									Dropdown Classes
										.dropdown-menu-dark 		- dark dropdown (desktop only, will be white on mobile)
										.dropdown-menu-hover 		- open on hover
										.dropdown-menu-clean 		- no background color on hover
										.dropdown-menu-invert 		- open dropdown in oposite direction (left|right, according to RTL|LTR)
										.dropdown-click-ignore 		- keep dropdown open on inside click (useful on forms inside dropdown)

										Dropdown prefix icon (optional, if enabled in variables.scss)
											.prefix-link-icon .prefix-icon-dot 		- link prefix
											.prefix-link-icon .prefix-icon-line 	- link prefix
											.prefix-link-icon .prefix-icon-ico 		- link prefix
											.prefix-link-icon .prefix-icon-arrow 	- link prefix

											.prefix-icon-ignore 					- ignore, do not use on a specific link

								-->
								<div aria-labelledby="dropdownProfileMenu" class="prefix-link-icon prefix-icon-arrow dropdown-menu dropdown-menu-clean dropdown-menu-invert dropdown-click-ignore p-0 mt--18 fs--15">
									<div class="dropdown-header">
										{{ user ? user.name : '' }}
									</div>
									<div class="dropdown-divider"></div>
									<li class="dropdown-item dropdown">
										<a href="#" class="dropdown-link" data-toggle="dropdown">Preferences</a>
										<ul class="dropdown-menu dropdown-menu-hover dropdown-menu-block-md min-w-200">
											<label class="form-switch form-switch form-switch-gray d-block dropdown-item-switch">
												<span class="float-left">Dark Mode</span>
												<input id="darkModeToggle" type="checkbox" v-model="$parent.darkMode" />
												<i data-on="ON" data-off="OFF" ></i>
											</label>
											<div class="dropdown-divider"></div>
											<router-link :to="{path:'/reset'}" class="dropdown-link" data-toggle="dropdown" style="padding-left: 15px !important;">Reset Local Cache</router-link>
										</ul>
									</li>
									<a href="https://vivint.okta.com/enduser/settings" title="Manage Profile" target="_blank" class="dropdown-item text-truncate font-weight-light external-link">
										Manage Profile
									</a>
									<div class="dropdown-divider mb-0"></div>
									<a href="#" v-on:click="$parent.logout()" title="Sign Out" class="prefix-icon-ignore dropdown-footer dropdown-custom-ignore">
										<i class="fi fi-power float-start"></i>
										Sign Out
									</a>
								</div>

							</li>

							<li class="list-inline-item mx-1 dropdown">

								<a id="btnGlobalSearch" href="#" aria-label="website search" class="btn-sow-search-toggler btn btn-sm rounded-circle btn-primary btn-soft-static" v-on:click="toggleSearch" onclick="return(false);">
									<i class="fi fi-search"></i>
								</a>

							</li>
						</ul>
						<!-- /OPTIONS -->



					</nav>
				</div>

	</header>
	<!-- /AppHeader -->
</template>
