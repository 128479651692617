<script>
export default {
	name: 'PageHeader',
	components: {
	},
	props: {
        title: [String, Array],
        headerTypeSpeed: {
            type: String,
            default: "40"
        },
        headerBackspaceSpeed: {
            type: String,
            default: "40"
        },
        headerBackspaceDelay: {
            type: String,
            default: "700"
        },
        headerNumLoops: {
            type: String,
            default: "1"
        },
        headerSmartBackspace: {
            type: String,
            default: "true"
        },
        headerShuffle: {
            type: String,
            default: "false"
        },
        headerCursor: {
            type: String,
            default: "|"
        },
        headerClass: {
            type: String,
            default: 'text-white'
        },
		user: {
			type: Object,
			default: null,
		},
		route: {
			type: Object,
			default: null,
		},
	},
	methods: {
	},
	data() {
		return {
			
        };
	},
    computed: {
        pageTitle() {
            if(typeof this.title === 'string') {
                return this.title
            }else{
                return this.title.join('|')
            }
        }
    },
    watch: {
        title: function(newTitle, oldTitle) {
            $.SOW.vendor.typed.init('.typed');
		},
    },
	mounted() {
        if($.SOW.vendor.typed !== undefined) {
            $.SOW.vendor.typed.init('.typed');
        }else{
            setTimeout(function() {
                $.SOW.vendor.typed.init('.typed');
            }, 1000);
        }
	}
};
</script>
<template>
    <section class="page-header">
        <div class="overlay dark-2"></div>
        <div class="container">
            <h1>
                <span><i class="fal fa-angle-right"></i></span> <span :class="'typed ' + headerClass" 
                                    :data-typed-string="pageTitle"
                                    :data-typed-speed-forward="headerTypeSpeed" 
                                    :data-typed-speed-back="headerBackspaceSpeed" 
                                    :data-typed-back-delay="headerBackspaceDelay" 
                                    :data-typed-loop-times="headerNumLoops"
                                    :data-typed-smart-backspace="headerSmartBackspace"
                                    :data-typed-shuffle="headerShuffle" 
                                    :data-typed-cursor="headerCursor"></span>
            </h1>
            <ol class="breadcrumb"></ol>
        </div>
    </section>
</template>