<script>
import Vue from 'vue';

export default Vue.extend({
	inheritAttrs: false,
	props: {
		label: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			default: '',
		},
		value: {
			type: [String, Number],
			default: '',
		},
	},
	computed: {
		listeners() {
			return {
				...this.$listeners,
				change: event => this.$emit('change', event.target.value),
				input: event => this.$emit('input', event.target.value),
			};
		},
	},
});
</script>

<template>
	<label class="select">{{ label }}
		<span class="select-wrapper">
			<select
				:name="name"
				:value="value"
				v-bind="$attrs"
				v-on="listeners"
			>
				<slot />
			</select>
		</span>
	</label>
</template>

<style lang="less" scoped>

// Variables for use throughout
@import (reference) '../assets/variables.less';

.select {
	display: inline-block;
	max-width: 500px;
	text-align: left;
}

.select-wrapper {
	display: block;
	width: 100%;
	position: relative;

	// Custom arrow to replace default one
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 50%;
		margin-top: -2.5px;
		right: 15px;
		border: 5px solid transparent;
		border-top-color: @input-color;

		// Must apply cursor to pseudo-element, ignore pointer events
		cursor: pointer;
		pointer-events: none;
	}
}

select {
	display: block;
	width: 100%;
	line-height: 1;
	appearance: none; // Remove default arrow in favor of custom one
	cursor: pointer;
}
</style>
