import Vue from 'vue';
import authAxios from '@/state/auth-axios'; // Use this to make okta-auth'd axios requests

// === SERVICES
function fetchSubdata(args) {
	const { path } = args;

	// prepare url for request
	const url = `https://vue-okta-starter/${path}`;

	// Determine query string params
	const params = {
	};

	// Create request and return promise for processed data
	return authAxios.get(url, {
		params,
	})
	// Success
		.then((response) => {
			const { data } = response;

			return {
				data,
			};
		})

		// Error
		.catch((err) => {
			throw err;
		});
}

// === STATE
// Prepare initial state, store builder
export const initialSubState = {
};

// === GETTERS
function sampleGetter(state) {
	return (path) => {
		if (!state[path]) return [];
		return Object.keys(state[path].data);
	};
}

// === MUTATIONS
function subdataMutation(state, data) {
	Vue.set(state, data.path, data.data); // For reactivity
}

// === ACTIONS
function getSubdataAction({ state, commit }, path) {
	return fetchSubdata({ path })
		.then((subdata) => {
			// If data doesn't already exist in store, add it
			if (!state[path]) {
				const subdataPayload = {
					path,
					data: subdata,
				};

				// Commit document to store
				commit('SET_SUBDATA', subdataPayload);
			}

			return subdata;
		})
		.catch((err) => {
			const msg = {
				type: 'error',
				message: 'Error retrieving subdata',
			};
			commit('ADD_MESSAGE', msg, { root: true });
			throw err;
		});
}

// exported module interface
export default {
	namespaced: true,
	state: initialSubState,
	getters: {
		sampleGetter,
	},
	mutations: {
		SET_SUBDATA: subdataMutation,
	},
	actions: {
		getSubdata: getSubdataAction,
	},
};
