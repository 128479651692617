import Vue from 'vue';
import Router from 'vue-router';
// https://github.com/declandewet/vue-meta
import VueMeta from 'vue-meta';
// Adds a loading bar at the top during page loads.
import NProgress from 'nprogress';
import store from '@/state/store';
import routes from './routes';

import axios from 'axios'
import VueAxios from 'vue-axios'
import { _ } from 'core-js';

import Swal from 'sweetalert2/dist/sweetalert2.min.js';
const Dialog = Swal.mixin({
	customClass: {
		confirmButton: 'btn btn-primary mr-3',
		cancelButton: 'btn btn-secondary'
	},
	buttonsStyling: false
});

const ProgressDialog = Swal.mixin({
	showCancelButton: false,
	showConfirmButton: false,
	title: 'Loading...',
	html: '<i class="fs--30 fi fi-spin fi-loading text-primary mt-2"></i>'
});

const Toast = Swal.mixin({
	toast: true,
	position: 'top-end',
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
	  toast.addEventListener('mouseenter', Swal.stopTimer)
	  toast.addEventListener('mouseleave', Swal.resumeTimer)
	}
});

Vue.prototype.$alert = Swal;
Vue.prototype.$dialog = Dialog;
Vue.prototype.$progressDialog = ProgressDialog;
Vue.prototype.$toast = Toast;


// Attach router/meta plugins to vue
Vue.use(Router);
Vue.use(VueMeta, {
	// The component option name that vue-meta looks for meta info on.
	keyName: 'page',
});
Vue.use(VueAxios, axios);
Vue.use(require('vue-truncate'));

// Async function to init router and return $auth for use in guard
const initAuth = async () => {
	let configErr;
	if (Vue.prototype.$auth === void 0) {
		try {
			await store.dispatch('getConfig', '/config.json');
		} catch (e) {
			configErr = true;
		}
	}
	if (configErr) return false;
	if (store.state.config && !store.state.userInfo) {
		
	}
	return Vue.prototype.$auth;
};

// Create router
const router = new Router({
	routes,
	// Use the HTML5 history API (i.e. normal-looking routes)
	// instead of routes with hashes (e.g. example.com/#/about).
	// This may require some server configuration in production:
	// https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
	mode: 'history',
	// Simulate native-like scroll behavior when navigating to a new
	// route and using back/forward buttons.
	scrollBehavior(_to, _from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		}
		return { x: 0, y: 0 };
	},
});

// Before each route evaluates...
router.beforeEach(async (to, from, next) => {
	// Skip auth check on auth-error, avoid endless loop
	if (to.name === 'auth-error') return next();

	// Skip auth check on 404, avoid endless loop
	if (to.name === '404') return next();

	// Ensure vue.prototype.$auth is available for templates
	const vueAuth = await initAuth();
	
	// Auth fails init when config is missing
	if (!vueAuth) {
		return next({
			name: '404',
			params: {
				code: 'auth_guard',
				message: 'Unable to load app configuration',
			},
		});
	}
	
	return next();
});

// Verify granular access to routes
router.beforeEach(async (to, _from, next) => {
	// If page doesn't require any specific roles, skip this guard
	if (!to.meta.requiresRole) return next();

	// Retrieve userinfo and check against page meta info
	let { userInfo } = store.state;

	// If userinfo doesn't yet exist in store, get from okta directly (if available)
	if (!userInfo) {
		const { app } = router;
		const oktaUserInfo = await app.$auth.getUser();
		const oktaAccessToken = app.$auth.getAccessToken();
		store.commitSetUser({
			user: oktaUserInfo,
			accessToken: oktaAccessToken,
		});
		({ userInfo } = store.state);
	}

	// Verify user meets requirements
	const groups = store.getUserRoles;
	if (userInfo && groups.indexOf(to.meta.requiresRole) !== -1) return next();

	// Redirect to error
	return next({
		name: 'auth-error',
		params: {
			code: 'invalid_role',
			message: 'User does not have a role required to view this resource',
		},
	});
});

// After navigation is confirmed, but before resolving...
router.beforeResolve((_routeTo, routeFrom, next) => {
	// If this isn't an initial page load...
	if (routeFrom.name) {
		// Start the route progress bar.
		NProgress.start();
	}
	next();
});

// When each route is finished evaluating...
router.afterEach(() => {
	// Complete the animation of the route progress bar.
	NProgress.done();
});

export default router;

// Set up default authentication header
axios.interceptors.request.use(async function (config) {
	NProgress.start();
	const { app } = router;
	if(app.$auth !== undefined) {
		var token = app.$auth.getAccessToken();
		config.headers['Authorization'] = 'Bearer ' + token;
	}
	return config;
}, function (error) {
	NProgress.done();
	// Do something with request error
	return Promise.reject(error);
});

axios.interceptors.response.use(async function (response) {
	NProgress.done();
	return response;
}, function (error) {
	NProgress.done();
	return Promise.reject(error);
});