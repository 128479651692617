import { Logger as SplunkLogger } from 'splunk-logging';

export default {
    data()
    {
        return {
            token: null,
            url: null,
            severity: "info",
        }
    },
    computed: {
        pageViewLogMessage: function ()
        {
            var page = this.$route.path;
            var username = this.$store.state.userInfo.email;

            return {
                page: page,
                userName: username
            };
        },
        pageViewLogMetaData: function ()
        {
            var source = window.location.origin;
            var host = window.location.hostname;

            return {
                source: source,
                host: host
            };
        },
        splunkConfig: function ()
        {
            return {
                token: this.token,
                url: this.url
            };
        }
    },
    methods: {
        logPageView()
        {
            var payload = {
                message: this.pageViewLogMessage,
                severity: this.severity,
                metadata: this.pageViewLogMetaData
            };
            this.log(payload);
        },
        log(payload)
        {
            if (!this.token || !this.url)
            {
                this.loadSplunkConfig();
            }
            var Logger = new SplunkLogger(this.splunkConfig);
            Logger.error = function (err, context)
            {
                console.error("Error while logging to splunk: ", err, context);
            };


            Logger.requestOptions.json = true;
            Logger.send(payload, function (err, resp, body)
            {
            });
        },
        loadSplunkConfig()
        {
            var config = this.$store.state.config

            this.url = config.splunk.url;
            this.token = config.splunk.token;
            if (config.splunk.severity)
            {
                this.severity = config.splunk.severity;
            }
        }
    }
}