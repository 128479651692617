<script>
import Vue from 'vue';

export default Vue.extend({
	inheritAttrs: false,
	props: {
		label: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: 'text',
		},
		name: {
			type: String,
			default: '',
		},
		value: {
			type: [String, Number, Boolean, Array],
			default: '',
		},
	},
	data() {
		return {
			valIsBoolean: false,
			valIsArray: false,
		};
	},
	computed: {
		hasLabel() {
			return this.label || !!this.$slots.default;
		},
		labelFirst() {
			return ['radio', 'checkbox'].indexOf(this.type) === -1;
		},
		checked() {
			if (['radio', 'checkbox'].indexOf(this.type) === -1) return null;
			if (this.type === 'radio') return this.value === this.$attrs.value;
			if (this.valIsBoolean) return !!this.value;
			if (this.valIsArray) return this.value.indexOf(this.$attrs.value) !== -1;
			return null;
		},
		listeners() {
			return {
				...this.$listeners,
				input: (event) => {
					let inputValue = event.target.value;

					// Handle checkboxes
					if (this.type === 'checkbox') {
						// Return checked status for boolean checkboxes
						if (this.valIsBoolean) inputValue = event.target.checked;
						if (this.valIsArray) {
							let arr = this.value;
							if (event.target.checked) arr.push(this.$attrs.value);
							else arr = arr.filter(val => val !== this.$attrs.value);
							inputValue = [...new Set(arr)]; // Remove duplicates
						}
					}

					// Handle radio buttons
					if (this.type === 'radio') inputValue = this.$attrs.value;

					this.$emit('input', inputValue);
				},
			};
		},
	},
	mounted() {
		// Determine whether value is boolean/array to help handling checkboxes/radios
		if (typeof this.value === 'boolean') this.valIsBoolean = true;
		if (Array.isArray(this.value)) this.valIsArray = true;
	},
});
</script>

<template>
	<label class="input">
		<span v-if="(hasLabel && labelFirst)">
			{{ label }}
			<slot />
		</span>
		<input
			v-if="(type !== 'textarea')"
			:name="name"
			:type="type"
			:value="value"
			:checked="checked"
			v-bind="$attrs"
			v-on="listeners"
		>
		<textarea
			v-if="(type === 'textarea')"
			:name="name"
			:value="value"
			v-bind="$attrs"
			v-on="listeners"
		/>
		<span v-if="(hasLabel && !labelFirst)">
			{{ label }}
			<slot />
		</span>
	</label>
</template>

<style lang="less" scoped>

.input {
	display: inline-block;
	max-width: 500px;
	text-align: left;

	input {
		display: block;
		width: 100%;
		line-height: 1;

		&[type='checkbox'],
		&[type='radio'] {
			width: auto;
		}
	}
}
</style>
