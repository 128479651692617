import Vue from 'vue';
import axios from 'axios';
import tokenProvider from 'axios-token-interceptor';

const authinstance = axios.create();
authinstance.interceptors.request.use(tokenProvider({
	getToken: () => Vue.prototype.$auth.getAccessToken(),
}));

export default authinstance;
